import type {ClassValue} from 'clsx';
import clsx from 'clsx';
import {twMerge} from 'tailwind-merge';

import type {LinkableDocumentTypes, LinkFragment} from '@/sanity/schemas/primitives/link';

import {ARTICLES_PAGE_PATH, PROJECTS_PAGE_PATH} from './constants';

export const checkIfLinkShouldUseAnchorTag = (href?: string) => {
	if (!href) return false;

	return (
		href?.startsWith('https://') ||
		href?.startsWith('http://') ||
		href?.startsWith('www.') ||
		href?.startsWith('mailto:') ||
		href?.startsWith('tel:')
	);
};

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function formatPhoneNumber(phoneNumber: string): string {
	// Check if the phone number starts with an international prefix (e.g., '+47')
	const hasInternationalPrefix = phoneNumber.startsWith('+');

	// Remove all non-digit characters from the phone number
	const digitsOnly = phoneNumber.replace(/\D/g, '');

	// Group every two digits
	const formatted = digitsOnly.replace(/(\d{2})(?=\d)/g, '$1 ');

	// Add back the international prefix if it was present
	return hasInternationalPrefix ? `+${formatted}` : formatted;
}

export function createPhoneLink(phoneNumber: string): string {
	// Remove all spaces and non-digit characters, except the plus sign at the beginning
	const cleanedNumber = phoneNumber.replace(/[^\d+]|(?<=\+)\s/g, '');

	// Return the formatted 'tel' link
	return `tel:${cleanedNumber}`;
}

export function formatDate(date: string) {
	const newDate = new Date(date);
	return newDate.toLocaleDateString('nb-NO', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});
}
export const internalLinkToHref = (documentType?: LinkableDocumentTypes, slug?: string) => {
	switch (documentType) {
		case 'frontpage':
			return '/';
		case 'page':
			return slug ? `/${slug}` : '#';
		case 'article':
			return slug ? `${ARTICLES_PAGE_PATH}/${slug}` : '#';
		case 'project':
			return slug ? `${PROJECTS_PAGE_PATH}/${slug}` : '#';

		default:
			console.warn('Invalid linkable document type:', documentType);
			return '#';
	}
};
export const externalLinkToHref = (href?: string) => href ?? '#';

export const resolveLinkHref = (link: LinkFragment) => {
	if (link.type === 'internal') {
		return internalLinkToHref(link.reference?._type, link.reference?.slug);
	}

	if (link.type === 'external') {
		return externalLinkToHref(link.href);
	}

	return '#';
};
