import type {ComponentPropsWithoutRef} from 'react';
import {forwardRef} from 'react';
import {Slot} from '@radix-ui/react-slot';

import {cn} from '@/shared';

type ParagraphProps = ComponentPropsWithoutRef<'p'> & {
	/** Changes text sizing */
	size?: 'small' | 'medium' | 'large';
	// /** Adds margin-bottom */
	// spacing?: boolean;
	// /** Reduces line-height for short paragraphs
	//  * @deprecated Use `variant="short"` instead
	//  */
	// short?: boolean;
	// /** Adjusts styling for paragraph length */
	// variant?: 'long' | 'short';
	/**
	 * Change the default rendered element for the one passed as a child, merging their props and behavior.
	 * @default false
	 */
	asChild?: boolean;
};

/** Use `Paragraph` to display text with paragraph text styles. */
export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
	(
		{
			size = 'medium',
			// spacing = false,
			className,
			asChild,
			...rest
		},
		ref,
	) => {
		const Component = asChild ? Slot : 'p';

		return (
			<Component
				ref={ref}
				className={cn([
					size === 'small' && ['text-base'],
					size === 'medium' && ['text-lg'],
					size === 'large' && ['text-xl'],
					// spacing && [
					// 	size === 'small' && ['mb-1'],
					// 	size === 'medium' && ['mb-2'],
					// 	size === 'large' && ['mb-3'],
					// ],
					className,
				])}
				{...rest}
			/>
		);
	},
);

Paragraph.displayName = 'Paragraph';
