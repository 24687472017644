import type {FC, PropsWithChildren} from 'react';

import {cn} from '@/shared';

import {Paragraph} from '../typography/paragraph';

interface Props extends PropsWithChildren {
	classNameOverride?: {
		figure?: string;
		figcaption?: string;
		credit?: string;
		caption?: string;
	};
	credit?: string;
	caption?: string;
}

export const Figure: FC<Props> = ({children, classNameOverride, credit, caption}) => {
	return (
		<figure className={cn(['relative', 'h-full', classNameOverride?.figure])}>
			{children}
			<figcaption
				className={cn([
					'flex',
					'flex-wrap',
					'justify-between',
					'gap-x-4',
					'gap-y-1',
					classNameOverride?.figcaption,
				])}
			>
				{caption && (
					<Paragraph asChild size="small">
						<span className={cn([classNameOverride?.caption])}>{caption}</span>
					</Paragraph>
				)}
				{credit && (
					<Paragraph asChild size="small">
						<strong className={cn([classNameOverride?.credit])}>{credit}</strong>
					</Paragraph>
				)}
			</figcaption>
		</figure>
	);
};
